$basepath: false !default;

@use "/node_modules/@domgen/dgx-fe-base-styles/scss/base-styles/abstracts/typography" as t;
@use 'base/inputs';

@use 'rnc-icons/style' with (
  $icomoon-font-path: if($basepath, $basepath + 'rnc-icons/', '') + 'fonts'
);

.sales {
  --my-account-main-title: #ec978b;
  --got-question-title: #ce3298;
  --user-idle-border-color: #e0543e;

  .rTapNumber4053 {
    @include t.body-large-bold();
    color: var(--tone-700);
    padding-top: 6px;
  }

  .main-container {
    background-color: var(--tone-300);
  }

  textarea.dg-textarea {
    max-width: 100%;
    padding: 10px;
  }
}

//todo: what is this?
a._acs {
  text-decoration: none !important;
}
