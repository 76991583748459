@use "/node_modules/@domgen/dgx-fe-base-styles/scss/base-styles/abstracts/forms" as f;
@use "/node_modules/@domgen/dgx-fe-base-styles/scss/base-styles/abstracts/typography" as t;
@use "/node_modules/@domgen/dgx-fe-base-styles/scss/base-styles/abstracts/breakpoints" as bp;
// TODO: check if these css is still used and update all the colours
$input-color-disabled: #5f5f5f;

form {
  width: 100%;
}

.form-input {
  margin-bottom: 30px;
  position: relative;

  span + .form-input-feedback {
    margin-bottom: 0;
  }
}

label,
.dg-hint {
  @include f.text--label();
  display: inline-block;
  padding-bottom: 7px;
}

.dg-hint-subText {
  @include f.text--hint();
}

body {
  & .p-autocomplete {
    display: block;

    & .p-autocomplete-input {
      padding: 12px 10px;
      max-width: 100%;
    }
  }
}

input,
textarea,
select {
  @include f.textfield();
}

select {
  max-width: 100%; // TODO: temporarily added to resolve the `About Fault Form' selector width;
}

.form-control {
  position: relative;

  &--small {
    max-width: 142px;
  }

  textarea,
  select {
    resize: none;
  }

  select,
  input {
    -webkit-appearance: none;
    &:not(:disabled) {
      cursor: pointer;
    }
    &[disabled] {
      background-color: var(--tone-200); // TODO: find or request an appropriate color mapping

      color: $input-color-disabled;
    }
    option {
      &:not(:first-of-type) {
        // all other options apart from placeholder
        color: var(--input-text-color);
      }
    }
  }

  // do not display native dropdown arrow in IE
  select::-ms-expand {
    display: none;
  }

  input {
    &:not(:disabled) {
      cursor: text;
    }
  }
}

.is-invalid .form-control {
  input,
  textarea,
  select {
    transition: all 0.8s ease-in-out;
    @include f.box--invalid();
  }

  .icon.icon-error-circle {
    @include f.validation-icon--invalid();
  }
}

.is-valid .form-control {
  input,
  textarea,
  select {
    @include f.box--valid();
  }
  .icon.icon-tick-circle {
    @include f.validation-icon--valid();
  }
}

.form-control:after {
  content: '';
  transition: all 0.2s ease-in-out;
  transform: scale(0);
  z-index: 1;
}

.round {
  //todo: is this really needed?
  //background-image: url('/assets/images/arrow-down.svg');
  background-position: calc(100% - 16px) calc(1em), calc(100% - 15px) calc(1em + 2px),
    calc(100% - 0.5em) 0.5em;
  background-size: 20px 20px, 20px 20px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

.round:focus {
  //todo: is this really needed?
  //background-image: url('/assets/images/arrow-up.svg');
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 0.5em) 0.5em;
  background-size: 20px 20px, 20px 20px, 1.5em 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.form-input-feedback {
  @include t.caption-regular();
  color: var(--error); // TODO: color check usage
  display: block;
  margin: 0;
  margin-top: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.button-group {
  text-align: center;

  randc-button:nth-child(odd) {
    margin-bottom: 20px;
  }

  &.button-group--link {
    ranc-button:nth-child(even) {
      margin-bottom: 10px;
    }

    ranc-button:nth-child(odd) {
      margin-bottom: 10px;
    }
  }
}

/* - Internet Explorer 10–11
   - Internet Explorer Mobile 10-11 */
:-ms-input-placeholder {
  color: brandcolour('placeholder-clr') !important;
  font-weight: 400 !important;
}

/* Edge (also supports ::-webkit-input-placeholder) */
::-ms-input-placeholder {
  color: brandcolour('placeholder-clr');
  font-weight: 400;
}

/* Firefox 4-18 */
:-moz-placeholder {
  color: brandcolour('placeholder-clr');
  font-weight: 400;
}

/* Firefox 19-50 */
::-moz-placeholder {
  color: brandcolour('placeholder-clr');
  font-weight: 400;
}

::placeholder {
  color: brandcolour('placeholder-clr');
}

/*tooltip related */

#tooltipFakeCheckbox {
  @include t.caption-regular();
  top: -1px;
  right: 5px;
  opacity: 0;
  z-index: 2;
}
.tooltip_questionMark {
  top: 1px;
  right: 8px;
  background-color: var(primary-600);
  border-radius: 50%;
  cursor: default;
  &:before {
    content: '?';
    font-weight: bold; // TODO: apply strong tags
    color: var(--primary-interactive-contrast-color);
  }
  + p {
    display: none;
    text-align: left;
    padding: 20px;
  }
}
.tooltip_randc {
  box-shadow: 0px 2px 4px #00000033;
  border-top: 3px var(secondary-600) solid;
  margin-bottom: 13px;
  background: var(--tone-200) 0% 0% no-repeat padding-box;
}
.tooltip_hint {
  border-left: 3px var(secondary-600) solid;
  border-top: 0px;
  display: block;
  padding: 20px;
}

#tooltipFakeCheckbox:checked ~ p {
  display: block;
  z-index: 1;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}
.form-control--small {
  max-width: 156px;
}

.dg-textarea {
  height: 152px;
  @include bp.breakpoint(0, md) {
    height: 145px;
  }
  @include bp.breakpoint(0, xs) {
    height: 210px;
  }

  @media (orientation: landscape) {
    @include bp.breakpoint(0, xs) {
      height: 145px;
    }
  }
}
