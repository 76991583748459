$icomoon-font-family: 'rnc-icons' !default;
$icomoon-font-path: 'fonts' !default;

$rnc-icon-dishwasher: '\e900';
$rnc-icon-fridge-freezer: '\e901';
$rnc-icon-multiproducts-01: '\e902';
$rnc-icon-washer-dryer: '\e903';
$rnc-icon-information: '\e905';
$rnc-icon-savings: '\e906';
$rnc-icon-email: '\e90a';
$rnc-icon-alert: '\e910';
$rnc-icon-annual-01: '\e911';
$rnc-icon-badge-01: '\e918';
$rnc-icon-breakdown: '\e91b';
$rnc-icon-calendar: '\e91d';
$rnc-icon-contact-centre: '\e923';
$rnc-icon-credit-card: '\e924';
$rnc-icon-cross: '\e925';
$rnc-icon-customer-satisfaction: '\e927';
$rnc-icon-diagnosis: '\e92e';
$rnc-icon-document: '\e934';
$rnc-icon-happy-face: '\e948';
$rnc-icon-location: '\e959';
$rnc-icon-pdf1: '\e96c';
$rnc-icon-plan: '\e971';
$rnc-icon-post: '\e973';
$rnc-icon-repair-engineer: '\e97e';
$rnc-icon-repair: '\e97f';
$rnc-icon-search: '\e982';
$rnc-icon-set-timer: '\e983';
$rnc-icon-tick: '\e98e';
$rnc-icon-uk-map: '\e9a3';
