//shared styles from published library @domgen/dgx-fe-base-styles
@use 'base-styles/base-styles';

//themes
@use 'base-styles/themes/ao';

@use 'legacy-styles/scss/styles';

.sales {
  @include ao.ao();
}
