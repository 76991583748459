@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?u3vljk') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?u3vljk') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?u3vljk##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.rnc-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rnc-icon-dishwasher {
  &:before {
    content: $rnc-icon-dishwasher;
  }
}
.rnc-icon-fridge-freezer {
  &:before {
    content: $rnc-icon-fridge-freezer;
  }
}
.rnc-icon-multiproducts-01 {
  &:before {
    content: $rnc-icon-multiproducts-01;
  }
}
.rnc-icon-washer-dryer {
  &:before {
    content: $rnc-icon-washer-dryer;
  }
}
.rnc-icon-information {
  &:before {
    content: $rnc-icon-information;
  }
}
.rnc-icon-savings {
  &:before {
    content: $rnc-icon-savings;
  }
}
.rnc-icon-email {
  &:before {
    content: $rnc-icon-email;
  }
}
.rnc-icon-alert {
  &:before {
    content: $rnc-icon-alert;
  }
}
.rnc-icon-annual-01 {
  &:before {
    content: $rnc-icon-annual-01;
  }
}
.rnc-icon-badge-01 {
  &:before {
    content: $rnc-icon-badge-01;
  }
}
.rnc-icon-breakdown {
  &:before {
    content: $rnc-icon-breakdown;
  }
}
.rnc-icon-calendar {
  &:before {
    content: $rnc-icon-calendar;
  }
}
.rnc-icon-contact-centre {
  &:before {
    content: $rnc-icon-contact-centre;
  }
}
.rnc-icon-credit-card {
  &:before {
    content: $rnc-icon-credit-card;
  }
}
.rnc-icon-cross {
  &:before {
    content: $rnc-icon-cross;
  }
}
.rnc-icon-customer-satisfaction {
  &:before {
    content: $rnc-icon-customer-satisfaction;
  }
}
.rnc-icon-diagnosis {
  &:before {
    content: $rnc-icon-diagnosis;
  }
}
.rnc-icon-document {
  &:before {
    content: $rnc-icon-document;
  }
}
.rnc-icon-happy-face {
  &:before {
    content: $rnc-icon-happy-face;
  }
}
.rnc-icon-location {
  &:before {
    content: $rnc-icon-location;
  }
}
.rnc-icon-pdf1 {
  &:before {
    content: $rnc-icon-pdf1;
  }
}
.rnc-icon-plan {
  &:before {
    content: $rnc-icon-plan;
  }
}
.rnc-icon-post {
  &:before {
    content: $rnc-icon-post;
  }
}
.rnc-icon-repair-engineer {
  &:before {
    content: $rnc-icon-repair-engineer;
  }
}
.rnc-icon-repair {
  &:before {
    content: $rnc-icon-repair;
  }
}
.rnc-icon-search {
  &:before {
    content: $rnc-icon-search;
  }
}
.rnc-icon-set-timer {
  &:before {
    content: $rnc-icon-set-timer;
  }
}
.rnc-icon-tick {
  &:before {
    content: $rnc-icon-tick;
  }
}
.rnc-icon-uk-map {
  &:before {
    content: $rnc-icon-uk-map;
  }
}
